
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LazyLoadWebComponent extends Vue {
  $refs!: {
    container: HTMLElement;
  };

  @Prop({ default: '' }) src!: string;

  mounted(): void {
    const script = document.createElement('script');
    script.src = this.src;
    script.type = 'module';
    this.$refs.container.appendChild(script);
  }
}
